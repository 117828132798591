import React, {Suspense} from 'react';
import {withTranslation} from 'react-i18next';
import {
  BrowserRouter,
  Route,
} from "react-router-dom";
import routes from "./routes";
import {AnimatedSwitch} from 'react-router-transition';
import MPageLoading from './ui/molecules/MPageLoading/index.js'
import {MenuProvider} from "./contexts/MenuContext";
import "./assets/scss/index.scss"
import SimpleReactLightbox from 'simple-react-lightbox'

function App() {
  return (
    <SimpleReactLightbox>
      <BrowserRouter>
        <Suspense fallback={<MPageLoading />}>
          <MenuProvider>
            <AnimatedSwitch
              atEnter={{opacity: 0}}
              atLeave={{opacity: 0}}
              atActive={{opacity: 1}}
              className="switch-wrapper"
            >
              {
                routes?.map((route, index) => {
                  if (route?.child) {
                    return (
                      route?.child.map((route, index) => (
                        <Route
                          key={route.slug}
                          exact
                          path={route.slug}
                          component={route.component}
                        />
                      ))
                    )
                  } else {
                    return (
                      <Route
                        key={route.slug + '-child'}
                        exact
                        path={route.slug}
                        component={route.component}
                      />
                    )
                  }
                })
              }
            </AnimatedSwitch>
          </MenuProvider>
        </Suspense>
      </BrowserRouter>
    </SimpleReactLightbox>
  );
}

export default withTranslation()(App);
