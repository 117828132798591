export default {
  sections: {
    first: {
      title: "КРУПНЕЙШАЯ СЕТЬ КОЛЕРОВОЧНЫХ ЦЕНТРОВ ПРОМЫШЛЕННЫХ КРАСОК В СТРАНАХ СНГ.<br><br>ОБЕСПЕЧИВАЕМ НЕПРЕВЗОЙДЕННОЕ УДОБСТВО СНАБЖЕНИЯ БЫСТРО, ТОЧНО В ЦВЕТ, В НУЖНОМ КОЛИЧЕСТВЕ.",
    },
    second: {
      title: "Решения для окраски ЖЕЛЕЗНОДОРОЖНОГО транспорта",
      link: "Системы окраски",
    },
    third: {
      title: "Решения для окраски Общественного транспорта",
      link: "Системы окраски",
    },
    fourth: {
      title: "Решения для окраски грузового автотранспорта",
      link: "Системы окраски",
    },
    fifth: {
      cards: {
        first: {
          title: "решения для окраски спецтехники и сельхоЗмашин",
        },
        second: {
          title: "решения для окраски станков и тяжелого оборудования",
        },
        third: {
          title: "решения для окраски металлоконструкций",
        },
        fourth: {
          title: "решения для окраски элементов городской инфраструктуры",
        },
      },
    },
    six: {
      cards: {
        first: {
          title: "Подбираем технологию",
          description: "Специалисты нашей компании подберут оптимальную технологию окраски основываясь на ваших пожеланиях и лучших мировых практиках в окраске аналогичных изделий",
        },
        second: {
          title: "Гарантируем качество",
          description: "Поставляемые решения, опробованы в многоступенчатых лабораторных испытаниях и проверены длительными сроками практической эксплуатации",
        },
        third: {
          title: "обеспечиваем высокий Сервис",
          description: "Поставим заказанный материал в день размещения заявки, подберем тон в тон по вашему образцу и без органичений по объему поставики",
        },
      }
    },
    seven: {
      pluses: {
        first: {
          title: "быстро",
          description: "Поставим заказанный материал<br> в день размещения заявки",
        },
        second: {
          title: "точно в цвет",
          description: "Подберем цвет по образцу<br> и обеспечим его постоянство",
        },
        third: {
          title: "в нужном количестве",
          description: "Без ограничений по объему<br> поставки",
        },
      },
      footer: {
        company_name: "ИУП «компания Арт Индустрия»",
        address: "ДИСТРИБЬЮТОРЫ В России, Беларуси, Казахстане, Узбекистане",
        email: "<a href='mailto:info@chemie-armor.com'>info@chemie-armor.com</a>",
      },
    },
  },
}