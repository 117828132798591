export default {
  title: 'Решения для окраски<br> спецтехники и сельхоЗмашин',
  sec1: {
    block1: 'Многие предприятия, производящие спецтехнику, изготавливают ее в течение всего года, а основные продажи происходят в сезонный период. Техника может находится длительное время на открытом воздухе под воздействием ультрафиолетового излучения, влаги иногда продолжительное время до момента отгрузки. Таким образом, важными являются вопросы сохранения блеска, цвета антикоррозионных свойств. В процессе эксплуатации, специальные транспортные средства подвержены интенсивному механическому воздействию коррозия: абразивный износ, удары камней, и пр., что требует достаточно высокого качества окраски. Окрашиваются сложные трехмерные объекты; используются различные типы подложек (сталь, чугун, алюминий, оцинковка, различные типы пластиков, элементы собранные и поставленные загрунтованными); применяется пневматическое, комбинированное или безвоздушное нанесение, эффективно применение установок для нанесения в электростатическом поле.',
    block2: 'ARMOR Chemie предлагает проверенные решения: однослойные (грунт-эмали DTM с фосфатом цинка) или двухслойные системы покрытий (первичная грунтовка плюс эмаль на видовых поверхностях). Номенклатура решений достаточна для применения ко всем основаниям и частям транспортного средства.',
  },
  sec2: {
    title: 'окраска прицепной<br> техники «hartung»',
    text: 'С 2006 года ПАО «ЧКПЗ» производит прицепную технику под торговой<br> маркой HARTUNG (ХАРТУНГ). Основное преимущество прицепной техники<br> HARTUNG — повышенный запас прочности.',
  },
  sec3: {
    title: 'для проекта применЯли<br> двухСЛОЙНую СИСТЕМу окраски',
    textsTitle: 'ARMOPUR Primer 011 + ARMOPUR DTM 113',
    texts: {
      block1: {
        title: 'Критерии выбора системы окраски:',
        list1: 'Первичная грунтовка 011 обеспечивает отличную адгезию к черному металлу и антикоррозионную защиту. Позволяет работать в системе «мокрый по мокрому». Быстро высыхает, что позволяет ходить по изделию во время нанесения финишного слоя, образовывая минимальное количество дефектов. Необходимость ходить по изделию во время окраски обусловлена конструктивными особенностями изделия и прочими фактами. Так же грунтовка хорошо подходит для нанесения установками безвоздушного распыления, не образовывая дефекты покрытия, что особенно важно при нанесении в труднодоступные места;',
        list2: 'Покрывная полуглянцевая грунт-эмаль 113 идеально «ложится» на загрунтованные поверхности и на голый металл, обеспечивая необходимую толщину и антикоррозионные свойства, так как содержит в своем составе активный антикоррозионный пигмент (фосфат цинка). Пригодна для нанесения установками безвоздушного распыления, не образовывая дефекты покрытия.',
      },
      block2: {
        title: 'Свойства полученного покрытия:',
        list1: 'Полуглянцевый финиш придает изделию элегантный внешний вид и скрывает часть дефектов, вызванных особенностями изготовления изделий;',
        list2: 'Высокая антикоррозионная защита;',
        list3: 'Прочное эластичное покрытие, стойкое к царапинам, сколам, ударам;',
        list4: 'Химостойкое.',
      },
    },
  },
  sec4: {
    subtitle: 'Под торговой маркой HARTUNG ЧКПЗ осуществляет производство тралов, полуприцепов и прицепов различных видов и модификаций: низкорамные полуприцепы, высокорамные тралы повышенной проходимости; бортовые полуприцепы повышенной проходимости (сортиментовозы/трубовозы); прицепы и полуприцепы сортиментовозы, самосвалы, панелевозы.',
    subtitle2: 'Брошюра с материалами по окраске специальной техники доступна для скачивания на русском и английском языках',
  },
  other_products: {
    card1: {
      title: 'ООО «ПК «Коммаш» (Москоммаш), Россия',
    },
    card2: {
      title: 'ОАО «Амкодор», Беларусь',
    },
    card3: {
      title: 'ООО «UzAuto Trailer», Узбекистан',
    },
  },
}