import {lazy} from "react";

const PHome = lazy(() => import("./ui/pages/PHome/index.js"));
const PCaseRailwayTransport = lazy(() => import("./ui/pages/PCase/PCaseRailwayTransport/index.js"));
const PCasePublicTransport = lazy(() => import("./ui/pages/PCase/PCasePublicTransport/index.js"));
const PCaseFreightVehicles = lazy(() => import("./ui/pages/PCase/PCaseFreightVehicles/index.js"));
const PCaseSpecialEquipment = lazy(() => import("./ui/pages/PCase/PCaseSpecialEquipment/index.js"));
const PCaseMachineTools = lazy(() => import("./ui/pages/PCase/PCaseMachineTools/index.js"));
const PCaseMetalStructures = lazy(() => import("./ui/pages/PCase/PCaseMetalStructures/index.js"));
const PCaseElementsInfrastructure = lazy(() => import("./ui/pages/PCase/PCaseElementsInfrastructure/index.js"));
const PContact = lazy(() => import("./ui/pages/PContact/index.js"));

export default [
  {
    slug: "/",
    text: {
      ru: "Главная",
      en: "Home",
      sr: "Početna stranica",
    },
    component: PHome
  },
  {
    slug: null,
    text: {
      ru: "Решения для окраски:",
      en: "PAINTING SOLUTIONS FOR:",
      sr: "REŠENJA ZA FARBANJE:",
    },
    child: [
      {
        slug: "/railway-transport",
        text: {
          ru: "подвижного состава ЖЕЛЕЗНОДОРОЖНОГО транспорта",
          en: "RAILWAY VEHICLES",
          sr: "VOZILA ŽELEZNIČKOG TRANSPORTA",
        },
        component: PCaseRailwayTransport
      },
      {
        slug: "/public-transport",
        text: {
          ru: "ОБщественного транспорта",
          en: "MUNICIPAL TRANSPORT",
          sr: "VOZILA JAVNOG PREVOZA",
        },
        component: PCasePublicTransport
      },
      {
        slug: "/freight-vehicles",
        text: {
          ru: "грузового автотранспорта",
          en: "COMMERCIAL VEHICLES",
          sr: "TERETNA VOZILA",
        },
        component: PCaseFreightVehicles
      },
      {
        slug: "/special-equipment",
        text: {
          ru: "спецтехники и сельхоЗмашин",
          en: "SPECIAL MACHINES AND FARM MACHINES",
          sr: "POSEBNA VOZILA I POLJOPRIVREDNE MAŠINE",
        },
        component: PCaseSpecialEquipment
      },
      {
        slug: "/machine-tools",
        text: {
          ru: "станков и тяжелого оборудования",
          en: "MACHINE-TOOLS AND HEAVY EQUIPMENT",
          sr: "INDUSTRIJSKE MAŠINE I TEŠKA OPREMA",
        },
        component: PCaseMachineTools
      },
      {
        slug: "/metal-structures",
        text: {
          ru: "металлоконструкций",
          en: "METAL STRUCTURES",
          sr: "METALNE KONSTRUKCIJE",
        },
        component: PCaseMetalStructures
      },
      {
        slug: "/elements-infrastructure",
        text: {
          ru: "элементов городской инфраструктуры",
          en: "URBAN INFRASTRUCTURE FACILITIES",
          sr: "ELEMENTI GRADSKE INFRASTRUKTURE",
        },
        component: PCaseElementsInfrastructure
      },
    ],
  },
  {
    slug: "/contact",
    text: {
      ru: "Контакты",
      en: "CONTACTS",
      sr: "KONTAKT",
    },
    component: PContact
  },
  {
    href: "https://chemie-armor-paints.pro/login",
    text: {
      ru: "Личный кабинет",
      en: "PERSONAL ACCOUNT",
      sr: "DEO ZA KUPCE",
    },
  },
]
