export default {
  title: 'PAINTING SOLUTIONS FOR<br> MACHINE-TOOLS AND HEAVY EQUIPMENT',
  sec1: {
    block1: 'The operating conditions of heavy equipment call not only for weather resistance, but also resistance to the effects of various chemically active compounds on the painted surface, as well as intense mechanical effects (scratches, impacts, abrasion). These factors call for sufficient chemical resistance and adequate physical and mechanical properties of paint and lacquer coating, in addition to aesthetic properties. Heavy equipment has a large number of steel and cast-iron units; they cannot undergo complex painting in the assembly, and artificial drying appears to be ineffective. Surface imperfections often require the use of matt and structured enamels to conceal surface treatment defects.',
    block2: 'Chemie Armor offers tested solutions based on two-component acrylic polyurethane materials. Single layer and double layer, designed for solid protection, durability and cost-effectiveness. We excel at special decorative solutions with a textured effect, as well as matt, semimatt, and semigloss.',
  },
  sec2: {
    title: 'PAINTING OF X-RAY<br> INSPECTION EQUIPMENT',
    text: 'DTP 7500LV is a portal X-ray inspection system<br> designed for scanning trucks, containers and airfreights.',
  },
  sec3: {
    title: 'A THREE-LAYER PAINTING SYSTEM<br> WAS USED FOR THE PROJECT',
    textsTitle: 'ARMEPOX ZNPRIMER 042 + ARMEPOX PRIMER 041 + ARMOPUR DTM 113 TEXTURED',
    texts: {
      block1: {
        title: 'Criteria for selecting the painting system:',
        list1: 'Primer 042 ensures maximum corrosion protection due to the high content of zinc powder in its composition and creates a “cold zinc plating” effect;',
        list2: 'Filler 041 creates a protective barrier while increasing the effectiveness of the primer;',
        list3: 'Textured coating enamel 113 creates an anti-glare effect and gives the product an imposing look.',
      },
      block2: {
        title: 'Properties of the resulting coating:',
        list1: 'Maximum protective properties for products operated in the harshest conditions, including in seaports on decks of ships;',
        list2: 'Imposing and businesslike product look;',
        list3: 'Durable resilient coating that is resistant to scratches, spalling, impacts;',
        list4: 'Chemical resistant.',
      },
    },
  },
  sec4: {
    subtitle: 'DTP 7500LV is a high-capacity high-efficiency transport inspection system. This facility can scan up to 80 vehicles per hour, making the DTP 7500LV an ideal solution for high-traffic areas.',
    subtitle2: 'A brochure with information on the painting of machine tools and heavy equipment is available for download in Russian and English',
  },
  other_products: {
    card1: {
      title: 'ATLANT CJSC, BELARUS',
    },
    // FYI: case hidden
    // card2: {
    //   title: 'ZERNOVAYA STOLITSA, UKRAINE',
    // },
    card3: {
      title: 'ALAGEUM ELECTRIC, KAZAKHSTAN',
    },
  },
}