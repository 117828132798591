export default {
  title: 'Решения для окраски станков и тяжелого оборудования',
  sec1: {
    block1: 'Условия эксплуатации тяжелого оборудования, помимо атмосферостойкости, предполагает воздействие на окрашенную поверхность различных химически активных составов, значительному механическому воздействию (царапины, удары, абразивный износ). Воздействие этих факторов, предполагает способность лакокрасочного покрытия помимо эстетических свойств, обладать достаточными химостойкостью и физико-механическими свойствами. Тяжелое оборудование имеет большое количество стальных и чугунных литых узлов; их нельзя подвергнуть комплексной окраске в сборе и искусственная сушка для них не эффективна. Зачастую Несовершенство поверхности предполагает применение матовых и структурных эмалей для маскировки дефектов подготовки поверхности.',
    block2: 'ARMOR Chemie предлагает проверенные решения на основе 2-х компонентных акрил-полиуретановых материалов. Однослойные, двухслойные, предназначенные для надежной защиты, долговечности и экономической эффективности. Наш конек – специальные декоративных решения с текстурным эффектом, матовые, полуматовые, полуглянцевые. ',
  },
  sec2: {
    title: 'окраска Досмотрового<br> рентгеновского<br> оборудования',
    text: 'DTP 7500LV — это портальный<br> инспекционно-досмотровый рентгеновский комплекс<br> спроектированный для сканирования грузовых<br> транспортных средств, контейнеров и авиагрузов.',
  },
  sec3: {
    title: 'для проекта применЯли<br> треХСЛОЙНую СИСТЕМу окраски',
    textsTitle: 'ARMEPOX ZnPrimer 042 + ARMEPOX<br> Primer 041 + ARMOPUR DTM 113 Текстурная',
    texts: {
      block1: {
        title: 'Критерии выбора системы окраски:',
        list1: 'Первая грунтовка 042 обеспечивает максимальную антикоррозионную защиту за счет высокого содержания цинкогово порошка в своем составе, создает эффект «холодного цинкования»;',
        list2: 'Вторая грунтовка 041 создает барьерную защиту, усиливая эффективность первой грунтовки;',
        list3: 'Покрывная эмаль 113 текстурная создает антибликовый эффект и придает изделию «строгий» внешний вид.',
      },
      block2: {
        title: 'Свойства полученного покрытия:',
        list1: 'Максимальные защитные свойства для изделий эксплуатирующихся в самых жестких условиях, в том числе в морских портах на палубах кораблей;',
        list2: '«Строгий» и «деловой» внешний вид изделий;',
        list3: 'Прочное эластичное покрытие, стойкое к царапинам, сколам, ударам;',
        list4: 'Химостойкое.',
      },
    },
  },
  sec4: {
    subtitle: 'DTP 7500LV —высокоэффективный комплекс сканирования транспортных средств с высокой пропускной способностью. Данный комплекс может просканировать до 80 транспортных средств в час, что делает DTP 7500LV идеальным решением для мест с высоким траффиком движения.',
    subtitle2: 'Брошюра с материалами по окраске станков и тяжелого оборудования доступна для скачивания на русском и английском языках',
  },
  other_products: {
    card1: {
      title: 'ЗАО «АТЛАНТ», Беларусь',
    },
    // FYI: case hidden
    // card2: {
    //   title: '«Зерновая Столица», Украина',
    // },
    card3: {
      title: '«Alageum Electric», Казахстан',
    },
  },
}