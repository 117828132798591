export default {
  title: 'Решения для окраски элементов<br> городской инфраструктуры',
  sec1: {
    block1: 'Окружающая среда и климатические воздействия могут привести к серьезному повреждению стальных конструкций. Очень важно обеспечить защиту от конструкций и сооружений. Системы защитных покрытий СА разработаны для того, чтобы выдерживать различные степени коррозийности окружающей среды.',
    block2: 'Мы предлагаем решения по защитным покрытиям для объектов, чтобы они прослужили долгие годы. С нашей продукцией мы предлагаем защиту в соответствии с международными стандартами EN ISO 12944 для всех классов коррозии. Ассортимент наших покрытий охватывает от защитных покрытий с высоким содержанием твердых частиц и воды до систем, не содержащих растворителей, для защиты новых конструкций, а также для реконструкции существующих конструкций. Защитные покрытия СА используются для мостов, пилонов, объектов инфраструктуры, металлоконструкций и конструкций залов, а также для электростанций, нефтегазовых объектов.',
  },
  sec2: {
    title: 'окраска трамвайных<br> остановок в минске',
    text: 'К открытию стадиона было решено провести и реконструкцию трамвайных<br> путей, благодаря чему возле «Динамо» появились новые стильные<br> остановки, которых до этого было всего несколько на весь город.',
  },
  sec3: {
    title: 'для проекта применЯли<br> двухСЛОЙНую СИСТЕМу окраски',
    textsTitle: 'ARMEPOX Primer 041 + ARMOPUR DTM 113',
    texts: {
      block1: {
        title: 'Критерии выбора системы окраски:',
        list1: 'Первичная грунтовка 041 обеспечивает отличную адгезию к различным типам поверхностей (алюминий, черный металл, оцинкованная сталь) и антикоррозионную защиту.',
        list2: 'Подходит для грунтования изделий очень сложной конфигурации, частично «прощает» недочеты при подготовке поверхности;',
        list3: 'Покрывная полуглянцевая грунт-эмаль 113 идеально «ложится» на загрунтованные поверхности и на голый металл, обеспечивая необходимую толщину и антикоррозионные свойства, так как содержит в своем составе активный антикоррозионный пигмент (фосфат цинка).',
      },
      block2: {
        title: 'Свойства полученного покрытия:',
        list1: 'Полуглянцевый финиш придает изделию элегантный внешний вид и скрывает часть дефектов, вызванных особенностями изготовления изделий;',
        list2: 'Высокая антикоррозионная защита;',
        list3: 'Прочное эластичное покрытие, стойкое к царапинам, сколам, ударам;',
        list4: 'Химостойкое, антивандальное.',
      },
    },
  },
  sec4: {
    subtitle: 'В прошлом году такие остановки уже были установлены на улицах Чапаева и Платонова, однако о конкретных планах на монтаж подобных конструкций в других частях города не сообщалось. Теперь же аналогичные остановки появились на улице Ульяновской.',
    subtitle2: 'Брошюра с материалами по окраске металлоконструкций доступна для скачивания на русском и английском языках',
  },
  other_products: {
    card1: {
      title: 'Игровые площадки, Казахстан',
    },
    card2: {
      title: 'Белреставрация, Беларусь',
    },
    card3: {
      title: 'Олди Свет, Беларусь',
    },
  },
}