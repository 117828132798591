import i18n from 'i18next';
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';
import ru from "../../static/localization/ru"
import en from "../../static/localization/en"
import sr from "../../static/localization/sr"

const resources = {
  ru: {
    translation: ru,
  },
  en: {
    translation: en,
  },
  sr: {
    translation: sr,
  }
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    // lng: 'ru',
    fallbackLng: 'en'
  }).then();

export default i18n;