export default {
  sections: {
    first: {
      title: "NAJVEĆI LANAC CENTARA ZA KOLORISTIKU INDUSTRIJSKIH BOJA U ZEMLJAMA ZND.<br><br>NUDIMO POGODNOST BRZE ISPORUKE TRAŽENE BOJE U TRAŽENOJ KOLIČINI.",
    },
    second: {
      title: "REŠENJA ZA FARBANJE VOZILA ŽELEZNIČKOG TRANSPORTA",
      link: "Sistemi boja",
    },
    third: {
      title: "REŠENJA ZA FARBANJE VOZILA JAVNOG PREVOZA",
      link: "Sistemi boja ",
    },
    fourth: {
      title: "REŠENJA ZA FARBANJE VOZILA TERETNOG PROGRAMA ",
      link: "Sistemi boja ",
    },
    fifth: {
      cards: {
        first: {
          title: "REŠENJA ZA FARBANJE VOZILA ZA POSEBNE NAMENE I POLJOPRIVREDNOG PROGRAMA ",
        },
        second: {
          title: "REŠENJA ZA FARBANJE INDUSTRIJSKIH MAŠINA I TEŠKE OPREME ",
        },
        third: {
          title: "REŠENJA ZA FARBANJE METALNIH KONSTRUKCIJA ",
        },
        fourth: {
          title: "REŠENJA ZA FARBANJE ELEMENATA GRADSKE INFRASTRUKTURE ",
        },
      },
    },
    six: {
      cards: {
        first: {
          title: "ODABIR PRAVE TEHNOLOGIJE ",
          description: "Stručnjaci naše kompanije odabraće optimalnu tehnologiju farbanja na osnovu vaših potreba i najboljih svetskih standarda u farbanju sličnih proizvoda. ",
        },
        second: {
          title: "GARANTUJEMO KVALITET ",
          description: "Isporučena rešenja su višestruko testirana u laboratorijskim ispitivanjima i dokazana dugotrajnošću u praksi. ",
        },
        third: {
          title: "NUDIMO VISOKI STEPEN USLUGE",
          description: "sporuka naručenog materijala u dogovorenom roku, odabir nijanse koji odgovara vašem uzorku i bez ograničenja u obimu isporuke",
        },
      }
    },
    seven: {
      pluses: {
        first: {
          title: "BRZO",
          description: "Naručeni materijal ćemo isporučiti na dan slanja narudžbe ",
        },
        second: {
          title: "TAČNA NIJANSA BOJE ",
          description: "Uklopićemo nijansu boje prema Vašem uzorku i osigurati njenu postojanost ",
        },
        third: {
          title: "U TRAŽENOJ KOLIČINI ",
          description: "Bez ograničenja u obimu<br> isporuke",
        },
      },
      footer: {
        company_name: "COLORFACTORY BALKAN d.o.o.",
        address: "DISTRIBUTER ZA SRBIJU, CRNU GORU, BOSNU I HRECEGOVINU, MAKEDONIJU I ALBANIJU",
        email: "<a href='mailto:info@chemie-armor.com'>info@chemie-armor.com</a>",
      },
    },
  },
}