export default {
  title: 'REŠENJA ZA FARBANJE INDUSTRIJSKIH MAŠINA I TEŠKE OPREME ',
  sec1: {
    block1: 'Uslovi rada teške opreme, pored vremenskih prilika, čine i uticaji različitih hemijski aktivnih supstanci na obojenu površinu, značajna mehanička naprezanja (ogrebotine, udarci, intenzivno habanje). Uticaj ovih faktora uzrokuje da sposobnost pokrivnih premaza, pored estetskih svojstava, ima i dovoljnu hemijsku otpornost te fizičko-mehanička svojstva. Teška oprema ima veliki broj čeličnih i livenih sklopova; ne mogu se podvrgnuti kompleksnom farbanju u sklopljenom obliku jer takav vid sušenja nije efikasan. Često površinske nepravilnosti uključuju upotrebu mat i teksturnih emajla za prikrivanje nedostataka u pripremi površine.',
    block2: 'Chemie Armor nudi dokazana rešenja zasnovana na dvokomponentnim akrilno-poliuretanskim materijalima. Jednoslojni i višeslojni premazi omogućavaju pouzdanu zaštitu, trajnost i isplativost. Naša jača strana su posebna dekorativna rešenja s teksturnim efektom, mat, polumat, polusjaj. ',
  },
  sec2: {
    title: 'FARBANJE OPREME<br> ZA ISPITIVANJE RTG ZRAKA',
    text: 'DTP 7500LV je mobilni sistem za rentgenski pregled dizajniran za<br> skeniranje teretnih vozila, kontejnera i vazdušnog tereta. ',
  },
  sec3: {
    title: 'PROJEKT JE REALIZOVAN<br> TROSLOJNIM SISTEMOM FARBE: ',
    textsTitle: 'ARMEPOX ZNPRIMER 042 + ARMEPOX PRIMER 041 + ARMOPUR DTM 113 TEKSTURNI',
    texts: {
      block1: {
        title: 'Kriterijum za odabir sistema farbanja:',
        list1: 'Prvi bazni premaz 042 pruža maksimalnu antikorozivnu zaštitu zbog visokog sadržaja cinkovog praha u svom sastavu, stvara efekt "hladnog cinkovanja";',
        list2: 'Drugi bazni premaz 041 stvara dodatnu barijeru, povećavajući efikasnost prvog baznog premaza; ',
        list3: 'Završni pokrivni emajl 113 stvara antirefleksni efekt i daje proizvodu "industrijski" izgled.',
      },
      block2: {
        title: 'Svojstva završnog sloja: ',
        list1: 'Maksimalna zaštitna svojstva za proizvode koji rade u najtežim uslovima, uključujući na palubama brodova; ',
        list2: '"Ozbiljan" i "industrijski" izgled proizvoda;',
        list3: 'Trajni elastični premaz, otporan na ogrebotine i udarce; ',
        list4: 'Otporan na hemikalije. ',
      },
    },
  },
  sec4: {
    subtitle: 'DTP 7500LV je kompleks za skeniranje vozila visokih performansi. Ovaj kompleks može skenirati do 80 vozila na sat, što DTP 7500LV čini idealnim rešenjem za područja velike frekvencije saobraćaja. ',
    subtitle2: 'Brošura sa materijalima za farbanje vozila železničkog prevoza dostupna je za preuzimanje na srpskom i engleskom jeziku',
  },
  other_products: {
    card1: {
      title: '"ATLANT" , BELORUSIJA',
    },
    // FYI: case hidden
    // card2: {
    //   title: '"ZERNOVAJA STOLICA", UKRAJINA ',
    // },
    card3: {
      title: '"ALAGEUM ELECTRIC", KAZAHSTAN ',
    },
  },
}