export default {
  title: 'REŠENJA ZA FARBANJE VOZILA TERETNOG PROGRAMA',
  sec1: {
    block1: 'Kamioni su izloženi intenzivnim faktorima okoline: koroziji, UV zračenju, diskoloraciji i matiranju boje, habanju, udarcima kamenom, reagensima na putu. Potrebni su premazi s maksimalnom zaštitom od korozije i trajnošću. Na primer, farbanje karoserija kamiona mora ne samo da izdrži veliku težinu, već mora biti otporno na habanje prilikom transporta rude, peska ili šljunka. Upečatljiva karakteristika farbanja kabina kamiona, zajedno sa zaštitnim svojstvima, su visoki zahtevi za izgledom ofarbane površine, približavajući se standardima kvaliteta za farbanje lakih putničkih vozila.',
    block2: 'Chemie Armor nudi dokazana rešenja: jednoslojni, dvoslojni i troslojni (među-brušenje da bi se dobio visoki sjaj) sistemi boja na bazi dvokomponentnih epoksidnih i akrilno-poliuretanskih komponenti. Takođe razvijamo posebna tehnička rešenja kao što su sastavi za bojenje elemenata enterijera s visokim dekorativnim i eksploatacionim karakteristikama ili rešenja za zaštitu od grafita. Paleta rešenja dovoljna je za primenu na sve vrste podloga i delova vozila. ',
  },
  // sec2: {
  //   title: 'FARBANJE CISTERNI "EVERLAST"',
  //   text: 'Ukrajinska kompanija Everlast specijalizovala se za proizvodnju automobilskih poluprikolica za prevoz svetlih i tamnih naftnih derivata, gasova, kao i prehrambenih i hemijskih proizvoda. ',
  // },
  sec3: {
    title: 'PROJEKAT JE REALIZOVAN<br> TROSLOJNIM SISTEMOM FARBE ',
    textsTitle: 'ARMEPOX Primer 041 + ARMOPUR<br> Filler 012 + ARMOPUR Topcoat 111',
    texts: {
      block1: {
        title: 'Kriterijum za odabir sistema farbanja:',
        list1: 'Primarni bazni premaz 011 pruža izvrsnu adheziju na crne metale i zaštitu od korozije. Omogućava rad po principu "mokro na mokro", omogućava lokalno gitovanje manjih površina bez potrebe za dodatnim operacijama pre nanošenja  završnih slojeva premaza, što značajno ubrzava postupak farbanja;',
        list2: 'Sekundarni bazni premaz 012 ispunjava površinu od sitnih nedostataka i jednostavan je za obradu (brušenje), stvarajući savršeno ravnu površinu za nanošenje završnog sloja. Kompatibilan sa svim vrstama punila (gita);',
        list3: 'Završno farbanje emajlom 111 boja se idealno "razliva" na površinu, omogućava dvobojno farbanje dajući proizvodu savršen finalni izgled. Pogodno za nanošenje sa bezvazdušnim sistemima za farbanje bez mogućnosti da se dese defekti u završnom sloju.',
      },
      block2: {
        title: 'Svojstva završnog sloja:',
        list1: 'Maksimalna dekorativna svojstva i sposobnost dugotrajnog očuvanja ovih svojstava;',
        list2: 'Visoka antikorozivna zaštita;',
        list3: 'Trajni elastični premaz otporan na ogrebotine i udare;',
        list4: 'Otporan na hemikalije, anti-vandalska svojstva. ',
      },
    },
  },
  sec4: {
    subtitle: 'Novi voz iz pogona "Stadler" već je počeo prevoziti putnike u Minskom metrou. Takvi vozovi su namenjeni trećoj liniji metroa - ona će se otvoriti na leto. Ali već se možete provozati modernim metroom duž linije Avtozavodskaja. ',
    subtitle2: 'Brošura sa materijalima za farbanje vozila železničkog prevoza dostupna je za preuzimanje na srpskom i engleskom jeziku',
  },
  other_products: {
    card1: {
      title: '"POŽSNAB", BELORUSIJA',
    },
    card2: {
      title: '„HYNDAI TRANS AUTO“, KAZAHSTAN',
    },
    card3: {
      title: '„SEMEYSKY MEHANIČKI ZAVOD“, KAZAHSTAN',
    },
  },
}