import home from "./home";
import common from "./common";
import page1 from "./page1";
import page2 from "./page2";
import page3 from "./page3";
import page4 from "./page4";
import page5 from "./page5";
import page6 from "./page6";
import page7 from "./page7";

export default {
  home,
  common,
  page1,
  page2,
  page3,
  page4,
  page5,
  page6,
  page7,
}