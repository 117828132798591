export default {
  countries: {
    ru: 'Россия',
    by: 'Беларусь',
    kz: 'Казахстан',
    uz: 'Узбекистан',
    kg: 'Кыргызская республика',
  },
  project: 'ПРОЕКТОВ',
  other_projects: 'Другие проекты',
  project_in_life: 'Проект в жизни',
  brochure: 'Брошюра',
}