export default {
  title: 'PAINTING SOLUTIONS FOR RAILWAY VEHICLES',
  sec1: {
    block1: 'Railway transport operates in various climatic regions and in various weather conditions.  The paint and lacquer coating of trains is exposed to destructive impacts: weather (sun, moisture, temperature fluctuations), mechanical (rocks, scratches, vandalism). These factors call for the heightened capacity of paint and lacquer coating to prevent corrosion, retain its original color and gloss, offer sufficient chemical resistance and ensure adequate physical and mechanical properties.',
    block2: 'Chemie Armor offers tested solutions based on two-component epoxy and acrylic polyurethane materials. Single layer, double layer, triple layer, designed for solid protection, durability and cost-effectiveness. We also develop special technical solutions, such as interior painting systems with high decorative and performance characteristics or anti-graffiti solutions. The range of our solutions is sufficient for all types of substrates and vehicle parts.',
  },
  sec2: {
    title: 'PAINTING OF MINSK METRO TRAINS',
    text: 'In 2017, Minsk Metro KTUP and CJSC Stadler Minsk<br> signed an agreement to supply 10 trains with asynchronous traction<br> drives in 2019–2020.',
  },
  sec3: {
    title: 'A MULTILAYER PAINTING<br> SYSTEM WAS USED FOR THE PROJECT ',
    textsTitle: 'ARMEPOX PRIMER 041 + ARMOPUR<br> FILLER 012 + ARMOPUR TOPCOAT 112 ',
    texts: {
      block1: {
        title: 'Criteria for selecting the painting system:',
        list1: 'Primer 041 ensures excellent adhesion to various types of surfaces (aluminum, black metal, galvanized steel) and corrosion protection;',
        list2: 'Filler 012 levels the surface, deals with minor defects and is easy to process (polish), creating a perfectly smooth surface for the application of the topcoat. Compatible with all types of fillers;',
        list3: 'High gloss topcoat 112 perfectly covers a surface, enables two-color painting and is easy to polish, giving a product its finished look.',
      },
      block2: {
        title: 'Properties of the resulting coating:',
        list1: 'Maximized decorative properties and the capacity to retain them for a long time;',
        list2: 'High corrosion protection;',
        list3: 'Durable resilient coating that is resistant to scratches, spalling, impacts; ',
        list4: 'Chemical resistant, vandal-proof.',
      },
    },
  },
  sec4: {
    subtitle: 'The first batch of railway vehicles was put into operation in the fourth quarter of 2017. The first streetcars produced by Stadler were successfully delivered to St. Petersburg for commercial operation.',
    subtitle2: 'A brochure with information on the painting of railway vehicles is available for download in Russian and English',
  },
  other_products: {
    card1: {
      title: 'CJSC STADLER MINSK, BELARUS',
    },
    card2: {
      title: 'UNITARY ENTERPRISE MINSK BRANCH OF BELARUSIAN RAILWAY, BELARUS',
    },
    card3: {
      title: 'JSC ALMATY CAR REPAIR PLANT, KAZAKHSTAN',
    },
  },
}
