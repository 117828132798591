export default {
  title: 'Решения для защитной<br> окраски металлоконструкций',
  sec1: {
    block1: 'Окружающая среда и климатические воздействия могут привести к серьезному повреждению стальных конструкций. Очень важно обеспечить защиту от конструкций и сооружений. Системы защитных покрытий СА разработаны для того, чтобы выдерживать различные степени коррозийности окружающей среды.',
    block2: 'Мы предлагаем решения по защитным покрытиям для объектов, чтобы они прослужили долгие годы. С нашей продукцией мы предлагаем защиту в соответствии с международными стандартами EN ISO 12944 для всех классов коррозии. Ассортимент наших покрытий охватывает от защитных покрытий с высоким содержанием твердых частиц и воды до систем, не содержащих растворителей, для защиты новых конструкций, а также для реконструкции существующих конструкций. Защитные покрытия СА используются для мостов, пилонов, объектов инфраструктуры, металлоконструкций и конструкций залов, а также для электростанций, нефтегазовых объектов.',
  },
  sec2: {
    title: 'окраска фасадов магазинов<br> торговой сети «ЕВРООПТ»',
    text: 'В 2018 году, в крупнейшей в Беларуси торговой сети «Евроопт» решили<br> сменить фирменный стиль. Мы были выбраны поставщиком для окраски<br> фасадов из вентилируемых алюминиевых кассет в более чем 100 объектах<br> сети.',
  },
  sec3: {
    title: 'для проекта применЯли<br> ОДНОСЛОЙНую СИСТЕМу окраски',
    textsTitle: 'ARMOPUR DTM 113НВ',
    texts: {
      block1: {
        title: 'Критерии выбора системы окраски:',
        list1: 'Покрывная полуглянцевая грунт-эмаль 113НВ идеально «ложится» на «старое» покрытие и на голый металл, обеспечивая необходимую толщину и антикоррозионные свойства, так как содержит в своем составе активный антикоррозионный пигмент (фосфат цинка). Быстро высыхает и наносится всеми методами нанесения, что делает ее пригодной для нанесения в полевых условиях, а так же обладает высокой дуракоустойчивостью.',
      },
      block2: {
        title: 'Свойства полученного покрытия:',
        list1: 'Полуглянцевый внешний вид скрывает дефекты, которые могут появляться при окраске больших поверхностей в условиях окраски «на объекте»;',
        list2: 'Высокая антикоррозионная защита;',
        list3: 'Прочное эластичное покрытие, стойкое к царапинам, сколам, ударам;',
        list4: 'Химостойкое, антивандальное.',
      },
    },
  },
  sec4: {
    subtitle: 'Многие уже заметили, что все новые торговые объекты «Евроопт» представлены в новых фирменных цветах - зеленом и графитовом, которые постепенно приходят на смену прежнему стилю.',
    subtitle2: 'Брошюра с материалами по окраске металлоконструкций доступна для скачивания на русском и английском языках',
  },
  other_products: {
    card1: {
      title: 'Новый музей ВОВ, Беларусь',
    },
    card2: {
      title: 'СОК Фристайл, Беларусь',
    },
    card3: {
      title: 'Аквапарк Лебяжий, Беларусь',
    },
  },
}