import React from "react";
import "./styles.scss"
import {ReactComponent as IconLoading} from "./../../../assets/svg/loading.svg"

export default () => {
  return (
    <div className="m-page-loading">
      <IconLoading />
    </div>
  )
}