import React from "react";

const MenuContext = React.createContext(false)

export function MenuProvider({ children }) {
  const [visibility, setVisibility] = React.useState(false)

  const toggleVisibility = (customState) => {
    const customStateExists = typeof customState !== 'undefined'
    setVisibility((state) => customStateExists ? customState : !state)
  }

  return (
    <MenuContext.Provider
      value={{
        visibility,
        toggleMenu: toggleVisibility,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

export function useMenu() {
  return React.useContext(MenuContext)
}