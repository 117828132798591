export default {
  title: 'PAINTING SOLUTIONS<br> FOR MUNICIPAL TRANSPORT',
  sec1: {
    block1: 'Municipal passenger transport is massively exposed to destructive environmental effects: corrosion, UV irradiation, sunburns and clouding, abrasive wear, impacts of rocks, and road reagents. Exposure to these factors requires the use of paint and lacquer coating with maximum corrosion protection and durability. Various types of substrates are used in the manufacturing process, surface preparation must be perfect, and lots of intermediate operations are required. There is another requirement for passenger transport painting, apart from high protective properties — the excellent external appearance of the coating, almost on a par with privately-owned vehicles.',
    block2: 'Chemie Armor offers tested solutions: two- and three-layer (with intermediate polishing for high gloss) painting systems based on two-component epoxy and acrylic polyurethane materials. We also develop special technical solutions, such as interior painting systems with high decorative and performance characteristics or anti-graffiti solutions. The range of our solutions is sufficient for all types of substrates and vehicle parts.',
  },
  sec2: {
    title: 'PAINTING STREETCARS<br> FOR ST. PETERSBURG ',
    text: 'In August 2016, Stadler Minsk and Transport Concession Company (TKK)<br> signed a contract for the delivery of 23 Metelitsa B85600M passenger<br> articulated streetcars nicknamed Chizhik for operation in St. Petersburg.',
  },
  sec3: {
    title: 'A MULTILAYER PAINTING SYSTEM<br> WAS USED FOR THE PROJECT',
    textsTitle: 'ARMEPOX Primer 041 + ARMOPUR<br> Filler 012 + ARMOPUR Topcoat 112',
    texts: {
      block1: {
        title: 'Criteria for selecting the painting system:',
        list1: 'Primer 041 ensures excellent adhesion to various types of surfaces (aluminum, black metal, galvanized steel) and corrosion protection;',
        list2: 'Filler 012 levels the surface, deals with minor defects and is easy to process (polish), creating a perfectly smooth surface for the application of the topcoat. Compatible with all types of fillers;',
        list3: 'High gloss topcoat 112 perfectly covers a surface, enables two-color painting and is easy to polish, giving a product its finished look.',
      },
      block2: {
        title: 'Properties of the resulting coating:',
        list1: 'Maximized decorative properties and the capacity to retain them for a long time;',
        list2: 'High corrosion protection;',
        list3: 'Durable resilient coating that is resistant to scratches, spalling, impacts;',
        list4: 'Chemical resistant, vandal-proof.',
      },
    },
  },
  sec4: {
    subtitle: 'The first batch of railway vehicles was put into operation in the fourth quarter of 2017. The first streetcars produced by Stadler were successfully delivered to St. Petersburg for commercial operation.',
    subtitle2: 'A brochure with information on the painting of municipal vehicles is available for download in Russian and English',
  },
  other_products: {
    // FYI: case hidden
    // card1: {
    //   title: 'TATRA-YUG LLC, UKRAINE',
    // },
    card2: {
      title: 'SAMAVTO LLC, UZBEKISTAN',
    },
    card3: {
      title: 'HYUNDAI TRANS AUTO LLP, KAZAKHSTAN',
    },
  },
}