export default {
  title: 'REŠENJA ZA FARBANJE ELEMENATA GRADSKE INFRASTRUKTURE ',
  sec1: {
    block1: 'Okolina i klimatski uticaji mogu naneti ozbiljnu štetu čeličnim konstrukcijama. Vrlo je važno osigurati zaštitu od okoline. C-A zaštitni premazni sistemi dizajnirani su da izdrže različit stepen korozivne okoline.',
    block2: 'Pružamo rešenja za zaštitne premaze za predmete koji traju godinama. Svojim proizvodima nudimo zaštitu u skladu s međunarodnim standardima EN ISO 12944 za sve klase korozije. Naš asortiman premaza kreće se od zaštitnih premaza s visokim s visokim sadržajem čvrstih materija i vode do sistema bez rastvarača za zaštitu novih građevina, kao i za obnovu postojećih struktura. Zaštitni premazi C-A koriste se za mostove, stubove, infrastrukturne objekte, metalne konstrukcije i konstrukcije hala, kao i za elektrane, postrojenja za naftu i gas. ',
  },
  sec2: {
    title: 'FARBANJE TRAMVAJSKIH<br> STANICA U MINSKU ',
    text: 'Pre otvaranja stadiona odlučeno je da se rekonstruiše tramvajska pruga, zahvaljujući čemu su se pojavila nova stilska tramvajska stajališta u blizini "Dinama", kojih je ranije bilo samo nekoliko u celom gradu.',
  },
  sec3: {
    title: 'PROJEKT JE REALIZOVAN DVOSLOJNIM SISTEMOM FARBE:',
    textsTitle: 'ARMEPOX Primer 041 + ARMOPUR DTM 113',
    texts: {
      block1: {
        title: 'Kriterijum za odabir sistema farbanja:',
        list1: 'Primarni bazni premaz 041 pruža izvrsnu adheziju na različite vrste površina (aluminijum, crni metal, pocinkovani čelik) i zaštitu od korozije. ',
        list2: 'Završni - pokrivni polusjajni emajl 113 idealno se "razliva" na površinu gde je nanešen prajmer i na goli metal, pružajući potrebnu debljinu i antikorozivna svojstva, jer sadrži aktivni antikorozivni pigment (cink fosfat). Pogodno za nanošenje sa bezvazdušnim sistemima za farbanje bez mogućnosti da se dese defekti u završnom sloju ',
      },
      block2: {
        title: 'Svojstva završnog sloja:',
        list1: 'Polusjajni finiš daje proizvodu elegantan izgled i skriva neke nedostatke uzrokovane specifičnostima u proizvodnji proizvoda;',
        list2: 'Visoka antikorozivna zaštita; ',
        list3: 'Trajni elastični premaz, otporan na ogrebotine i udarce;',
        list4: 'Otporan na hemikalije.',
      },
    },
  },
  sec4: {
    subtitle: 'Prošle godine takva stajališta su već bila postavljena na ulicama Čapaev i Platonov, međutim najavljeni su posebni planovi za postavljanje takvih stajališta u drugim delovima grada. Sada su se slična stajališta pojavila u Uljanovskoj ulici. ',
    subtitle2: 'Brošura sa materijalima za farbanje vozila železničkog prevoza dostupna je za preuzimanje na srpskom i engleskom jeziku',
  },
  other_products: {
    card1: {
      title: 'IGRALIŠTA, KAZAHSTAN ',
    },
    card2: {
      title: 'BELRESTAVRACIJA, BELORUSIJA ',
    },
    card3: {
      title: 'OLDIE SVET, BELORUSIJA ',
    },
  },
}