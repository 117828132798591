export default {
  title: 'REŠENJA ZA FARBANJE METALNIH KONSTRUKCIJA',
  sec1: {
    block1: 'Okolina i klimatski uticaji mogu naneti ozbiljnu štetu čeličnim konstrukcijama. Vrlo je važno osigurati zaštitu od okoline. C-A zaštitni premazni sistemi razvijeni su da izdrže različit stepen korozivne okoline. ',
    block2: 'Pružamo rešenja za zaštitne premaze za predmete koji traju godinama. Svojim proizvodima nudimo zaštitu u skladu s međunarodnim standardima EN ISO 12944 za sve klase korozije. Naš asortiman premaza kreće se od zaštitnih premaza s visokim sadržajem čvrstih materija i vode do sistema bez rastvarača za zaštitu novih građevina kao i za obnovu postojećih struktura. Zaštitni premazi C-A koriste se za mostove, stubove, infrastrukturne objekte, metalne konstrukcije i konstrukcije hala, kao i za elektrane, postrojenja za naftu i gas. ',
  },
  sec2: {
    title: 'FARBANJE FASADA PRODAVNICA TRGOVINSKOG LANCA "EUROOPT" ',
    text: '2018. godine Euroopt, najveći maloprodajni lanac u Bjelorusiji, odlučio je da promeni korporativni identitet. Odabrani smo kao dobavljač za farbanje fasada sa ventilirajućim aluminijumskim panelima na više od 100 lokacija u lancu. ',
  },
  sec3: {
    title: 'PROJEKT JE REALIZOVAN<br> JEDNOSLOJNIM SISTEMOM FARBE: ',
    textsTitle: 'ARMOPUR DTM 113НВ',
    texts: {
      block1: {
        title: 'Kriterijum za odabir sistema farbanja: ',
        list1: 'Pokrivni polusjajni grund-emajl 113NV idealno se "razliva" na stari premaz i na goli metal, pružajući potrebnu debljinu i antikorozivna svojstva, jer sadrži aktivni antikorozivni pigment (cink fosfat). Brzo se suši i primenjuje se svim načinima nanošenja, što ga čini pogodnim za primenu na udaljenim lokacijama, a takođe ima i visoku otpornost na greške.',
      },
      block2: {
        title: 'Svojstva završnog sloja: ',
        list1: 'Polusjajni finiš skriva nedostatke koji se mogu pojaviti prilikom farbanja velikih površina u uslovima farbanja na licu mesta; ',
        list2: 'Visoka antikorozivna zaštita; ',
        list3: 'Trajni elastični premaz, otporan na ogrebotine i udarce; ',
        list4: 'Otporan na hemikalije, anti-vandalska svojstva. ',
      },
    },
  },
  sec4: {
    subtitle: 'Mnogi su već primetili da su svi novi maloprodajni objekti Euroopt predstavljeni u novim korporativnim bojama - zelenoj i grafitnoj, koje postepeno zamenjuju prethodni stil. ',
    subtitle2: 'Brošura sa materijalima za farbanje vozila železničkog prevoza dostupna je za preuzimanje na srpskom i engleskom jeziku',
  },
  other_products: {
    card1: {
      title: 'NOVI MUZEJ SVETA, BELORUSIJA ',
    },
    card2: {
      title: 'FREESTYLE SOK, BJELORUSIJA',
    },
    card3: {
      title: 'AQUAPARK LEBYAZHIY, BELORUSIJA ',
    },
  },
}