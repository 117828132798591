export default {
  title: 'PAINTING SOLUTIONS<br> FOR COMMERCIAL VEHICLES',
  sec1: {
    block1: 'Commercial vehicles are exposed to intense destructive environmental effects: corrosion, UV irradiation, sunburns and clouding, abrasive wear, impacts of rocks, and road reagents. They require the use of paint and lacquer coating with maximum corrosion protection and durability. For example, the paint and lacquer coating of truck bodies must not only withstand heavy weight, but also be resistant to abrasion caused by the transportation of ore, sand or gravel. There is another requirement for truck cabin painting, apart from high protective properties — the excellent external appearance of the coating, almost on a par with privately-owned vehicles.',
    block2: 'Chemie Armor offers tested solutions: single-, two- and three-layer (with intermediate polishing for high gloss) painting systems based on two-component epoxy and acrylic polyurethane materials. We also develop special technical solutions, such as interior painting systems with high decorative and performance characteristics or anti-graffiti solutions. The range of our solutions is sufficient for all types of substrates and vehicle parts.',
  },
  // FYI: case hidden
  // sec2: {
  //   title: 'PAINTING OF "EVERLAST"<br> PETROLEUM TANKS',
  //   text: 'Ukraine’s Everlast specializes in the manufacture of automotive semi-trailers for the transportation of light and dark petroleum products, gases, as well as food and chemical products.',
  // },
  sec3: {
    title: 'A THREE-LAYER PAINTING<br> SYSTEM WAS USED FOR THE PROJECT ',
    textsTitle: 'ARMEPOX Primer 041 + ARMOPUR<br> Filler 012 + ARMOPUR Topcoat 111',
    texts: {
      block1: {
        title: 'Criteria for selecting the painting system:',
        list1: 'Primer 011 ensures excellent adhesion to ferrous metals and corrosion protection. Enables wet-on-wet painting, allows local priming without additional operations before applying intermediate or finishing coats, which significantly expedites the painting process;',
        list2: 'Filler 012 levels the surface, deals with minor defects and is easy to process (polish), creating a perfectly smooth surface for the application of the topcoat. Compatible with all types of fillers;',
        list3: 'High gloss topcoat 111 perfectly covers a surface and enables two-color painting, giving a product its finished look. Suitable for airless spraying without causing coating defects.',
      },
      block2: {
        title: 'Properties of the resulting coating:',
        list1: 'Maximized decorative properties and the capacity to retain them for a long time;',
        list2: 'High corrosion protection;',
        list3: 'Durable resilient coating that is resistant to scratches, spalling, impacts;',
        list4: 'Chemical resistant, vandal-proof.',
      },
    },
  },
  sec4: {
    subtitle: 'Minsk Metro is breaking in new Stadler trains with passengers. These trains are intended for the third Metro line — it will be unveiled in summer. You can ride the new train on the Avtozavodskaya Line today, though.',
    subtitle2: 'A brochure with information on the painting of commercial vehicles is available for download in Russian and English',
  },
  other_products: {
    card1: {
      title: 'POZHSNAB LLC, BELARUS',
    },
    card2: {
      title: 'HYUNDAI TRANS AUTO LLP, KAZAKHSTAN',
    },
    card3: {
      title: 'SEMEY MECHANICAL PLANT LLP, KAZAKHSTAN',
    },
  },
}