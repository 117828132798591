export default {
    title: 'PAINTING SOLUTIONS FOR<br> URBAN INFRASTRUCTURE FACILITIES ',
    sec1: {
      block1: 'The environment and climate effects can cause serious damage to steel structures. It is critically important to ensure protection of structures and constructions. CA protective coating systems are designed to resist various degrees of environment-caused corrosion.',
      block2: 'We offer protective coating solutions for structures so that they will last for many years. Our products ensure protection according to EN ISO 12944 international standards for all corrosion classes. Our range of coatings spans from protective coatings with high content of solid particles and water to solvent-free systems for the protection of new structures as well as for the renovation of existing structures. CA protective coatings are used for bridges, pylons, infrastructure elements, steel structures and hall structures, as well as for power plants, oil and gas facilities. ',
    },
    sec2: {
      title: 'PAINTING OF SHOP FACADES<br> OF THE "EVROOPT" CHAIN ',
      text: 'In 2018, the largest retail chain in Belarus, Evroopt decided to to update its corporate style. We were selected as a supplier to paint facades made of ventilated aluminum cassettes in more than 100 stores of the chain.',
    },
    sec3: {
      title: 'A SINGLE-LAYER PAINTING<br> SYSTEM WAS USED FOR THE PROJECT',
      textsTitle: 'ARMOPUR DTM 113НВ',
      texts: {
        block1: {
          title: 'Criteria for selecting the painting system:',
          list1: 'Coating semi-glossy primer enamel 113НВ perfectly covers “old” surfaces and bare metal, ensuring the necessary thickness and anticorrosion properties, as it contains an active corrosion pigment (zinc phosphate). It dries fast, can be applied by all methods, which makes it suitable for application in the field, and is highly mistake-proof.',
        },
        block2: {
          title: 'Properties of the resulting coating:',
          list1: 'Its semi-glossy look conceals defects that can appear when painting large surfaces on the site;',
          list2: 'High corrosion protection;',
          list3: 'Durable resilient coating that is resistant to scratches, spalling, impacts;',
          list4: 'Chemical resistant, vandal-proof.',
        },
      },
    },
    sec4: {
      subtitle: 'Many have already noticed that all of the new retail outlets of Evroopt bear new corporate colors — green and graphite, which gradually replace the old style.',
      subtitle2: 'A brochure with information on the painting of metal structures is available for download in Russian and English',
    },
    other_products: {
      card1: {
        title: 'NEW MUSEUM OF THE GREAT PARTIOTIC WAR, BELARUS',
      },
      card2: {
        title: 'FREESTYLE SPORTS CENTER, BELARUS',
      },
      card3: {
        title: 'LEBYAZHY WATERPARK',
      },
    },
  }