export default {
  countries: {
    ru: 'Russia',
    by: 'Belarus',
    kz: 'Kazakhstan',
    uz: 'Uzbekistan',
    kg: 'Kyrgyz Republic',
  },
  project: 'Projects',
  other_projects: 'OTHER PROJECTS',
  project_in_life: 'REAL-LIFE OPERATION',
  brochure: 'BROCHURE',
}