export default {
  sections: {
    first: {
      title: "THE LARGEST NETWORK OF TINTING CENTERS FOR INDUSTRIAL PAINTS IN THE CIS.<br><br>WE DELIVER UNPARALLELED QUALITY: FAST SUPPLIES, EXACT COLOR MATCH, UNLIMITED AMOUNTS.",
    },
    second: {
      title: "PAINTING SOLUTIONS FOR RAILWAY VEHICLES",
      link: "Painting systems",
    },
    third: {
      title: "PAINTING SOLUTIONS FOR MUNICIPAL TRANSPORT ",
      link: "Painting systems",
    },
    fourth: {
      title: "PAINTING SOLUTIONS FOR COMMERCIAL VEHICLES",
      link: "Painting systems",
    },
    fifth: {
      cards: {
        first: {
          title: "PAINTING SOLUTIONS FOR SPECIAL MACHINES AND FARM MACHINES",
        },
        second: {
          title: "PAINTING SOLUTIONS FOR MACHINE-TOOLS AND HEAVY EQUIPMENT",
        },
        third: {
          title: "PAINTING SOLUTIONS FOR METAL STRUCTURES",
        },
        fourth: {
          title: "PAINTING SOLUTIONS FOR URBAN INFRASTRUCTURE FACILITIES",
        },
      },
    },
    six: {
      cards: {
        first: {
          title: "WE PICK THE RIGHT TECHNOLOGY",
          description: "Our specialists will choose the best painting technology based on your requirements and international best practices in the painting of similar products",
        },
        second: {
          title: "WE DELIVER EXCELLENCE",
          description: "We supply solutions that are tested in multi-stage laboratory trials and during many years of practical operation",
        },
        third: {
          title: "WE ENSURE HIGH-QUALITY SERVICE",
          description: "We deliver your order on the same day without any volume limitations and find the color exactly matching your sample",
        },
      }
    },
    seven: {
      pluses: {
        first: {
          title: "FAST",
          description: "Same day delivery of your order",
        },
        second: {
          title: "EXACT COLOR MATCH",
          description: "We find an exact match for your color samples and ensure the consistency of the tint",
        },
        third: {
          title: "THE AMOUNT YOU REQUIRE",
          description: "Unlimited supplies",
        },
      },
      footer: {
        company_name: "Foreign Unitary Company «ART INDUSTRY COMPANY»",
        address: "DISTRIBUTORS IN RUSSIA, BELARUS, KAZAKHSTAN, UZBEKISTAN",
        email: "<a href='mailto:info@chemie-armor.com'>info@chemie-armor.com</a>",
      },
    },
  },
}