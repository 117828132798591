export default {
  title: 'REŠENJA ZA FARBANJE VOZILA ŽELEZNIČKOG TRANSPORTA',
  sec1: {
    block1: 'Železnički transport obavlja se u širokom spektru klimatskih zona i pod različitim vremenskim uslovima. Premazi za vozove izloženi su destruktivnim efektima: atmosferskim (sunce, vlaga, promene temperature), mehaničkim (udarci kamenom, ogrebotine, manifestacije vandalizma). Uticaj svih ovih faktora uslovljava sposobnost premaza da spreče koroziju, zadrži izvornu boju i sjaj, te ima dovoljnu hemijsku otpornost i fizičko-mehanička svojstva.',
    block2: 'Chemie Armor nudi dokazana rešenja zasnovana na dvokomponentnim epoksidnim i akrilno-poliuretanskim materijalima. Jednoslojni, dvoslojni, trostruki sloj dizajniran za pouzdanu zaštitu, trajnost i isplativost. Takođe razvijamo posebna tehnička rešenja kao što su sistemi za farbanje elemenata enterijera s visokim dekorativnim i eksploatacionim karakteristikama ili rešenja za zaštitu od grafita i vandalizma. Paleta rešenja dovoljna je za primenu na sve vrste podloga i delova vozila.',
  },
  sec2: {
    title: 'FARBANJE METRO<br> VOZOVA U MINSKU ',
    text: '2017. između KTUP-a „Minsk Metro“ i CJSC „Stadler Minsk“<br> potpisan ugovor o isporuci 10 vozova asinhronog<br> pogona u 2019-2020.god ',
  },
  sec3: {
    title: 'PROJEKAT JE REALIZOVAN<br> VIŠESLOJNIM SISTEMOM FARBE ',
    textsTitle: 'ARMEPOX Primer 041 + ARMOPUR<br> Filler 012 + ARMOPUR Topcoat 112',
    texts: {
      block1: {
        title: 'Kriterijum za odabir sistema farbanja:',
        list1: 'Primarni bazni premaz 041 pruža izvrsnu adheziju na različite vrste površina (aluminijum, crni metal, pocinkovani čelik) i zaštitu od korozije;',
        list2: 'Sekundarni bazni premaz 012 ispunjava površinu od sitnih nedostataka i jednostavan je za obradu (brušenje), stvarajući savršeno ravnu površinu za nanošenje završnog sloja. Kompatibilan sa svim vrstama punila (gita);',
        list3: 'Završno farbanje emajlom visokog sjaja 112, boja se idealno "razliva" na površinu, omogućava dvobojno farbanje i lako se polira, dajući proizvodu savršen finalni izgled.',
      },
      block2: {
        title: 'Svojstva završnog sloja:',
        list1: 'Maksimalna dekorativna svojstva i sposobnost dugotrajnog očuvanja ovih svojstava;',
        list2: 'Visoka antikorozivna zaštita;',
        list3: 'Trajni elastični premaz otporan na ogrebotine i udare;',
        list4: 'Otporan na hemikalije, anti-vandalska svojstva.',
      },
    },
  },
  sec4: {
    subtitle: 'Eksploatacija prve serije voznog parka započela je u 4. kvartalu 2017. godine. Prvi tramvaji koje je proizveo Stadler uspešno su isporučeni u Sankt Peterburg za komercijalne operacije.',
    subtitle2: 'Brošura sa materijalima za farbanje vozila železničkog prevoza dostupna je za preuzimanje na srpskom i engleskom jeziku',
  },
  other_products: {
    card1: {
      title: 'STADLER MINSK CJSC, BELORUSIJA',
    },
    card2: {
      title: '"MINSK ODELJENJE ZA ŽELEZNICU", BELORUSIJA',
    },
    card3: {
      title: '"VAGONREMONTNI ZAVOD ALMATI", KAZAHSTAN',
    },
  },
}