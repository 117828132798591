export default {
  title: 'Решения для окраски<br> грузового автотранспорта ',
  sec1: {
    block1: 'Грузовые транспортные средства подвержены интенсивному воздействию факторов окружающей среды: коррозия, УФ-облучение, выгорание и помутнение, абразивный износ, удары камней, воздействие дорожных реагентов. Они требуют лакокрасочных покрытий с максимальной антикоррозионной защитой и долговечностью. Например ЛКП кузовов грузовых автомобилей должны не только выдерживать большой вес, но и быть устойчивыми к истиранию при транспортировке руды, песка или гравия. Яркой особенностью окраски кабин грузовиков, наряду с защитными свойствами, являются высокие требования к внешнему виду покрытия, приближающиеся к стандартам качества окраски легкового транспорта.',
    block2: 'ARMOR Chemie предлагает проверенные решения: однослойные, двухслойные и трехслойные (с промежуточным шлифованием для получения высокого блеска) системы окраски на основе 2-х компонентных эпоксидных и акрил-полиуретановых материалов. Мы также разрабатываем специальные технические решения, такие как системы окраски элементов интерьера внутри, с высокими декоративными и эксплуатационными характеристиками или решения для защиты от граффити. Номенклатура решений достаточна для применения ко всем основаниям и частям транспортного средства.',
  },
  // FYI: case hidden
  // sec2: {
  //   title: 'окраска нефтепродуктовых<br> цистерн «everlast»',
  //   text: 'Украинская компания Everlast специализируется на производстве автомобильных полуприцепов для транспортировки светлых и темных нефтепродуктов, газов, а также пищевых и химических продуктов.',
  // },
  sec3: {
    title: 'для проекта применЯли<br> треХСЛОЙНую СИСТЕМу окраски',
    textsTitle: 'ARMEPOX Primer 041 + ARMOPUR<br> Filler 012 + ARMOPUR Topcoat 111',
    texts: {
      block1: {
        title: 'Критерии выбора системы окраски:',
        list1: 'Первичная грунтовка 011 обеспечивает отличную адгезию к черному металлу и антикоррозионную защиту. Позволяет работать в системе «мокрый по мокрому», допускает проводить локальное подгрунтовывание без необходимости проведения дополнительных операций перед нанесением промежуточных или финишных слоев покрытия, что значительно ускоряет окрасочный процесс;',
        list2: 'Вторичная грунтовка 012 выравнивает поверхность от мелких дефектов и легко обрабатывается (шлифуется), создавая идеально ровную поверхность для нанесения финишного слоя. Совместима со всеми типами шпатлевок;',
        list3: 'Покрывная высоко глянцевая эмаль 111 идеально «ложится» на поверхность, позволяет проводить двухцветную окраску, придавая изделию законченный вид. Пригодна для нанесения установками безвоздушного распыления, не образовывая дефекты покрытия.',
      },
      block2: {
        title: 'Свойства полученного покрытия:',
        list1: 'Максимальные декоративные свойства и возможность их сохранять длительное время;',
        list2: 'Высокая антикоррозионная защита;',
        list3: 'Прочное эластичное покрытие, стойкое к царапинам, сколам, ударам;',
        list4: 'Химостойкое, антивандальное.',
      },
    },
  },
  sec4: {
    subtitle: 'В минском метро начали обкатывать уже с пассажирами новый поезд от завода «Штадлер». Такие составы предназначены для третьей ветки метро — ее откроют летом. Но прокатиться на современном поезде по Автозаводской линии подземки можно уже сейчас.',
    subtitle2: 'Брошюра с материалами по окраске грузового автотранспорта доступна для скачивания на русском и английском языках',
  },
  other_products: {
    card1: {
      title: 'ООО «Пожснаб», Беларусь',
    },
    card2: {
      title: 'ТОО «Hyndai Trans Auto», Казахстан',
    },
    card3: {
      title: 'ТОО «Семейский механический завод», Казахстан',
    },
  },
}