export default {
  title: 'PAINTING SOLUTIONS FOR<br> SPECIAL MACHINES AND FARM MACHINES',
  sec1: {
    block1: 'Many manufacturers of special equipment produce it throughout the year, while sales are for the most part seasonal. Machinery can remain outdoors for a long time, exposed to ultraviolet irradiation and moisture before shipment. Therefore, it is important to preserve gloss, color and ensure anticorrosion properties. During operation, special vehicles are exposed to severe mechanical effects and corrosion: abrasion, impacts of rocks, etc., which requires a sufficiently high quality of painting. Complex three-dimensional objects are painted; different types of substrates are used (steel, cast iron, aluminum, zinc-plated, various types of plastics, elements assembled and delivered primed); pneumatic, combined or airless application is used; electrostatic field application systems are effective.',
    block2: 'Chemie Armor offers tested solutions: single-layer (DTM zinc phosphate primer enamel) or two-layer coating systems (primer plus enamel on visible surfaces). The range of our solutions is sufficient for all types of substrates and vehicle parts.',
  },
  sec2: {
    title: 'PAINTING OF "HARTUNG"<br> TOWED VEHICLES',
    text: 'PJSC CHKPZ has manufactured towed vehicles under the HARTUNG<br> brand since 2006. The main advantage of HARTUNG towed vehicles<br> is their high strength and resistance.',
  },
  sec3: {
    title: 'A TWO-LAYER PAINTING SYSTEM WAS USED FOR THE PROJECT',
    textsTitle: 'ARMOPUR Primer 011 + ARMOPUR DTM 113',
    texts: {
      block1: {
        title: 'Criteria for selecting the painting system:',
        list1: 'Primer 011 ensures excellent adhesion to ferrous metals and corrosion protection. Enables wet-on-wet coating. It dries quickly, allowing the user to walk on the product during the application of the finish coat, while causing minimum defects. The need to walk on the product during painting is due to its design features and other factors. The same primer is also suitable for application by airless spraying without coating defects, which is especially important when applied in hard-to-reach places;',
        list2: 'Coating semi-glossy primer enamel 113 perfectly covers primed surfaces and bare metal, ensuring the necessary thickness and anticorrosion properties, as it contains an active corrosion pigment (zinc phosphate). Suitable for airless spraying without causing coating defects.',
      },
      block2: {
        title: 'Properties of the resulting coating:',
        list1: 'Semi-glossy finish gives the product an elegant look and conceals some of the defects caused by the peculiarities of the manufacture process;',
        list2: 'High corrosion protection;',
        list3: 'Durable resilient coating that is resistant to scratches, spalling, impacts;',
        list4: 'Chemical resistant.',
      },
    },
  },
  sec4: {
    subtitle: 'CHKPZ manufactures lowboys, semi-trailers and trailers of various types and modifications under the HARTUNG brand: low-frame semi-trailers, high-frame lowboys with a cross-country capacity; drop-side semitrailers with a cross-country capacity (log trucks/pipe carriers); trailers and semitrailers, log trucks, dump trucks, precast concrete panel trucks.',
    subtitle2: 'A brochure with information on the painting of special machines is available for download in Russian and English',
  },
  other_products: {
    card1: {
      title: 'PK KOMMASH LLC (MOSCOMMASH), RUSSIA',
    },
    card2: {
      title: 'AMKODOR JSC, BELARUS',
    },
    card3: {
      title: 'UZAUTO TRAILER LLC, UZBEKISTAN',
    },
  },
}