export default {
    title: 'PAINTING SOLUTIONS FOR<br> URBAN INFRASTRUCTURE FACILITIES',
    sec1: {
      block1: 'The environment and climate effects can cause serious damage to steel structures. It is critically important to ensure protection of structures and constructions. CA protective coating systems are designed to resist various degrees of environment-caused corrosion.',
      block2: 'We offer protective coating solutions for structures so that they will last for many years. Our products ensure protection according to EN ISO 12944 international standards for all corrosion classes. Our range of coatings spans from protective coatings with high content of solid particles and water to solvent-free systems for the protection of new structures as well as for the renovation of existing structures. CA protective coatings are used for bridges, pylons, infrastructure elements, steel structures and hall structures, as well as for power plants, oil and gas facilities.',
    },
    sec2: {
      title: 'PAINTING OF TRAM STOPS IN MINSK',
      text: 'Tram tracks were reconstructed for the grand opening of the Dynamo stadium, and new stylish stops appeared nearby. Stops of this kind had only been installed in a few locations of the city before the Dynamo project.',
    },
    sec3: {
      title: 'A TWO-LAYER PAINTING SYSTEM WAS USED FOR THE PROJECT',
      textsTitle: 'ARMEPOX PRIMER 041 + ARMOPUR DTM 113',
      texts: {
        block1: {
          title: 'Criteria for selecting the painting system:',
          list1: 'Primer 041 ensures excellent adhesion to various types of surfaces (aluminum, black metal, galvanized steel) and corrosion protection.',
        },
        block2: {
          title: 'Properties of the resulting coating:',
          list1: 'Semi-glossy finish gives the product an elegant look and conceals some of the defects caused by the peculiarities of the manufacture process;',
          list2: 'High corrosion protection;',
          list3: 'Durable resilient coating that is resistant to scratches, spalling, impacts;',
          list4: 'Chemical resistant, vandal-proof.',
        },
      },
    },
    sec4: {
      subtitle: 'Last year, such stops were installed in Chapaev and Platonov Streets; however, there were no plans to install such constructions in other parts of the city. Similar stops have now been installed in Ulyanovskaya Street.',
      subtitle2: 'A brochure with information on the painting of metal structures is available for download in Russian and English',
    },
    other_products: {
      card1: {
        title: 'PLAYGROUNDS, KAZAKHSTAN',
      },
      card2: {
        title: 'BELRESTAVRATSIYA, BELARUS',
      },
      card3: {
        title: 'OLDI SVET, BELARUS',
      },
    },
  }