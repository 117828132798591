export default {
  title: 'REŠENJA ZA FARBANJE VOZILA JAVNOG PREVOZA',
  sec1: {
    block1: 'Putnički prevoz podložan je intenzivnim destruktivnim efektima faktora okoline: korozija, UV zračenje, diskoloracija i matiranje boje, abrazivno habanje, udarci kamenom, reagensi na putevima. Uticaj ovih faktora zahteva upotrebu boja i lakova s ​​maksimalnom antikorozivnom zaštitom i trajnošću. U proizvodnom procesu koriste se razne vrste podloga, potreban je najviši kvalitet pripreme površine i mnogo drugih među-koraka. Još jedna upečatljiva karakteristika boja za javni putnički transport, zajedno sa zaštitnim svojstvima, su visoki zahtevi za izgledom ofarbane površine, praktično isto kao i za putnički prevoz.',
    block2: 'Chemie Armor nudi dokazana rešenja: dvoslojne i troslojne (među-brušenje da bi se dobio visoki sjaj) sisteme boja na bazi dvokomponentnih epoksidnih i akrilno-poliuretanskih materijala. Takođe razvijamo posebna tehnička rešenja kao što su sastavi za bojenje elemenata enterijera s visokim dekorativnim i eksploatacionim karakteristikama ili rešenja za zaštitu od grafita. Paleta rešenja dovoljna je za primenu na sve vrste podloga i delova vozila.',
  },
  sec2: {
    title: 'FARBANJE TRAMVAJA<br> ZA SANKT-PETERBURG',
    text: 'U avgustu 2016. godine Stadler Minsk i kompanija za transportnu koncesiju (TKK)<br> potpisali su ugovor o isporuci 23 putnička zglobna tramvajska vozila<br> marke Metelica, model B85600M, nazvanog Ćižik,<br> za korišćeenje u Sankt Peterburgu.',
  },
  sec3: {
    title: 'PROJEKAT JE REALIZOVAN<br> VIŠESLOJNIM SISTEMOM FARBE',
    textsTitle: 'ARMEPOX Primer 041 + ARMOPUR<br> Filler 012 + ARMOPUR Topcoat 112',
    texts: {
      block1: {
        title: 'Kriterijum za odabir sistema farbanja:',
        list1: 'Primarni bazni premaz 041 pruža izvrsnu adheziju na različite vrste površina (aluminijum, crni metal, pocinkovani čelik) i zaštitu od korozije;',
        list2: 'Sekundarni bazni premaz 012 ispunjava površinu od sitnih nedostataka i jednostavan je za obradu (brušenje), stvarajući savršeno ravnu površinu za nanošenje završnog sloja. Kompatibilan sa svim vrstama punila (gita);',
        list3: 'Završno farbanje emajlom visokog sjaja 112, boja se idealno "razliva" na površinu, omogućava dvobojno farbanje i lako se polira, dajući proizvodu savršen finalni izgled.',
      },
      block2: {
        title: 'Svojstva završnog sloja:',
        list1: 'Maksimalna dekorativna svojstva i sposobnost dugotrajnog očuvanja ovih svojstava;',
        list2: 'Visoka antikorozivna zaštita;',
        list3: 'Trajni elastični premaz otporan na ogrebotine i udare;',
        list4: 'Otporan na hemikalije, anti-vandalska svojstva.',
      },
    },
  },
  sec4: {
    subtitle: 'Eksploatacija prve serije voznog parka započela je u 4. kvartalu 2017. godine. Prvi tramvaji koje je proizveo Stadler uspešno su isporučeni u Sankt Peterburg za komercijalne operacije.',
    subtitle2: 'Brošura sa materijalima za farbanje vozila železničkog prevoza dostupna je za preuzimanje na srpskom i engleskom jeziku',
  },
  other_products: {
    // FYI: case hidden
    // card1: {
    //   title: '"TATRA-YUG", UKRAJINA',
    // },
    card2: {
      title: '"SAMAVTO", UZBEKISTAN',
    },
    card3: {
      title: 'HYUNDAI TRANS AUTO, KAZAHSTAN ',
    },
  },
}