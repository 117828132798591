export default {
  title: 'Решения для окраски<br> Общественного транспорта',
  sec1: {
    block1: 'Пассажирский транспорт подвержен интенсивному разрушающему воздействию факторов окружающей среды: коррозия, УФ-облучение, выгорание и помутнение, абразивный износ, удары камней, дорожные реагенты. Воздействие этих факторов, требует применения лакокрасочных покрытий с максимальной антикоррозионной защитой и долговечностью. В процессе изготовления используются различные типы подложек, требуется максимальное качество подготовки поверхности и множество промежуточных операций. Еще одной яркой особенностью окраски пассажирского транспорта, наряду с защитными свойствами, являются высокие требования к внешнему виду покрытия, почти как к легковому транспорту.',
    block2: 'ARMOR Chemie предлагает проверенные решения: двухслойные и трехслойные (с промежуточным шлифованием для получения высокого блеска) системы окраски на основе 2-х компонентных эпоксидных и акрил-полиуретановых материалов. Мы также разрабатываем специальные технические решения, такие как системы окраски элементов интерьера внутри, с высокими декоративными и эксплуатационными характеристиками или решения для защиты от граффити. Номенклатура решений достаточна для применения ко всем основаниям и частям транспортного средства.',
  },
  sec2: {
    title: 'окраска трамваев<br> для санкт-петербурга',
    text: 'В августе 2016 года «Штадлер Минск» и «Транспортная<br> концессионная компания (ТКК)» заключили договор на поставку<br> 23 пассажирских сочленённых трамвайных вагонов «Метелица»<br> модификации В85600М, получивших название «Чижик»,<br> для эксплуатации в Санкт-Петербурге.',
  },
  sec3: {
    title: 'для проекта применЯли<br> многослойную систему окраски',
    textsTitle: 'ARMEPOX Primer 041 + ARMOPUR<br> Filler 012 + ARMOPUR Topcoat 112',
    texts: {
      block1: {
        title: 'Критерии выбора системы окраски:',
        list1: 'Первичная грунтовка 041 обеспечивает отличную адгезию к различным типам поверхностей (алюминий, черный металл, оцинкованная сталь) и антикоррозионную защиту;',
        list2: 'Вторичная грунтовка 012 выравнивает поверхность от мелких дефектов и легко обрабатывается (шлифуется), создавая идеально ровную поверхность для нанесения финишного слоя. Совместима со всеми типами шпатлевок;',
        list3: 'Покрывная высоко глянцевая эмаль 112 идеально «ложится» на поверхность, позволяет проводить двухцветную окраску и отлично полируется, придавая изделию законченный вид.',
      },
      block2: {
        title: 'Свойства полученного покрытия:',
        list1: 'Максимальные декоративные свойства и возможность их сохранять длительное время;',
        list2: 'Высокая антикоррозионная защита;',
        list3: 'Прочное эластичное покрытие, стойкое к царапинам, сколам, ударам;',
        list4: 'Химостойкое, антивандальное.',
      },
    },
  },
  sec4: {
    subtitle: 'Эксплуатация первой партии подвижного состава началась в IV квартале 2017 года. Первые трамваи производства «Штадлер» успешно поставлены в Санкт-Петербург для коммерческой эксплуатации.',
    subtitle2: 'Брошюра с материалами по окраске общественного транспорта доступна для скачивания на русском и английском языках',
  },
  other_products: {
    // FYI: case hidden
    // card1: {
    //   title: 'ООО «Татра-Юг», Украина',
    // },
    card2: {
      title: 'ООО «СамАвто», Узбекистан',
    },
    card3: {
      title: 'ТОО «Hyundai Trans Auto», Казахстан',
    },
  },
}