export default {
  title: 'REŠENJA ZA FARBANJE VOZILA ZA POSEBNE NAMENE I POLJOPRIVREDNOG PROGRAMA',
  sec1: {
    block1: 'Mnogi proizvođači vozila za posebne potrebe ova vozila proizvode tokom cele godine, a glavna prodaja odvija se tokom sezonskog perioda. Vozila mogu dugo stajati na otvorenom pod uticajem ultraljubičastog zračenja i vlage, do trenutka otpreme. Stoga su pitanja očuvanja sjaja, boje i antikorozivnih svojstava važna. Tokom rada posebna vozila su izložena intenzivnoj mehaničkoj koroziji: abrazivnom trošenju, udarcima kamenom itd.,što zahteva veoma visok kvalitet farbanja. Farbaju se složeni trodimenzionalni delovi; koriste se razne vrste podloga (čelik, liveno gvožđe, aluminijum, pocinkovani lim, razne vrste plastike, elementi koji se sastavljaju i isporučuju u osnovnoj boji); koriste se pneumatski, kombinovani ili bezvazdušni sistemi za farbanje, efikasna je upotreba elektrostatičkog farbanja. ',
    block2: 'Chemie Armor nudi dokazana rešenja: jednoslojni premaz (DTM cink-fosfatni temeljni emajl) ili dvoslojni sistem (osnovni premaz + završni emajl). Paleta rešenja dovoljna je za primenu na sve vrste podloga i delova vozila. ',
  },
  sec2: {
    title: 'FARBANJE „HARTUNG“ VUČNE<br> OPREME I PRIKOLICA ',
    text: 'Od 2006. godine, "ČKPZ" proizvodi vučnu opremu pod trgovačkom markom HARTUNG.',
  },
  sec3: {
    title: 'PROJEKT JE REALIZOVAN<br> DVOSLOJNIM SISTEMOM FARBE:',
    textsTitle: 'ARMOPUR Primer 011 + ARMOPUR DTM 113',
    texts: {
      block1: {
        title: 'Kriterijum za odabir sistema farbanja:',
        list1: 'Primarni bazni premaz 011 pruža izvrsnu adheziju na crne metale i zaštitu od korozije. Omogućava rad po principu "mokro na mokro. Brzo se suši kako bi omogućio hodanje po ofarbanoj površini tokom nanošenja završnog premaza uz minimalne greške. Potreba za hodanjem po proizvodu tokom farbanja je uzrokovana dizajnerskim karakteristikama proizvoda i drugih detalja;',
        list2: 'Završni - pokrivni polusjajni emajl 113 idealno se "razliva" na površinu gde je nanešen prajmer i na goli metal, pružajući potrebnu debljinu nanosa i antikorozivna svojstva, jer sadrži aktivni antikorozivni pigment (cink fosfat). Pogodno za nanošenje sa bezvazdušnim sistemima za farbanje bez mogućnosti da se dese defekti u završnom sloju.',
      },
      block2: {
        title: 'Svojstva završnog sloja:',
        list1: 'Polusjajni finiš daje proizvodu elegantan izgled i skriva neke nedostatke uzrokovane specifičnostima u proizvodnji proizvoda; ',
        list2: 'Visoka antikorozivna zaštita; ',
        list3: 'Trajni elastični premaz, otporan na ogrebotine i udarce; ',
        list4: 'Otporan na hemikalije.',
      },
    },
  },
  sec4: {
    subtitle: 'Pod zaštitnim znakom HARTUNG, ČKPZ proizvodi prikolice, poluprikolice i prikolice različitih vrsta i modifikacija: poluprikolice s niskim utovarivačem, sa visokim okvirom; poluprikolice s ravnim platformama povećane nosivosti (nosači drva / cevi); prikolice i poluprikolice za kamione za prevoz drva, kiperi i teretna vozila.',
    subtitle2: 'Brošura sa materijalima za farbanje vozila železničkog prevoza dostupna je za preuzimanje na srpskom i engleskom jeziku',
  },
  other_products: {
    card1: {
      title: 'PK "KOMMAŠ" (MOSKOMMAŠ), RUSIJA',
    },
    card2: {
      title: '"AMKODOR", BELORUSIJA',
    },
    card3: {
      title: '"UZAUTO PRIKOLICE", UZBEKISTAN',
    },
  },
}